<template>
  <app-layout>
    <v-layout>
      <v-flex xs12>
        <company-selector />
        <v-card class="pb-3">
          <v-card-title>
            <h3>Stammdaten {{ currentCompany.name }}</h3>
            <v-spacer />
            <v-text-field
              :value="search"
              @input="debounceSearch"
              append-icon="search"
              ref="search"
              label="Suche"
              single-line
              hide-details
            />
          </v-card-title>
          <v-btn
            :to="{ name: 'MasterDataCreate' }"
            color="primary"
            dark
            v-if="isEditor"
            >Neuer Stammdatensatz
          </v-btn>
          <v-data-table
            v-if="serverData"
            v-bind="{ loading, headers, items }"
            :hide-actions="pagination.totalItems === 0"
            :pagination.sync="pagination"
            :rows-per-page-items="[10]"
            :total-items="this.serverData.total"
          >
            <v-progress-linear slot="progress" color="accent" indeterminate />
            <template slot="items" slot-scope="props">
              <tr
                :key="props.item.guid"
                @click="
                  $router.push({
                    name: 'MasterDataView',
                    params: { id: props.item.id },
                  })
                "
              >
                <td>{{ props.item.ags }}</td>
                <td>{{ props.item.referenceNumber }}</td>
                <td>{{ props.item.municipality }}</td>
                <td>
                  {{ props.item.parent && props.item.parent.municipality }}
                </td>
                <td>
                  {{ props.item.addressAdministrativeMunicipality.name }}
                </td>
                <td>{{ props.item.creditor }}</td>
                <td>
                  {{ props.item.uniformMunicipalRateSince }}
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <div class="text-xs-center">
                <template v-if="search.length >= 3"
                  >Es wurden keine Einträge gefunden.</template
                >
                <template v-else>Bitte einen Suchbegriff eingeben.</template>
              </div>
            </template>
            <template slot="pageText" slot-scope="props">
              Einträge {{ props.pageStart }} bis {{ props.pageStop }} von
              insgesamt
              {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import masterdataApi from '@/api/masterdataApi'
import CompanySelector from '@/components/CompanySelector'
import * as Sentry from '@sentry/vue'

export default {
  name: 'MasterData',
  components: { CompanySelector, AppLayout },
  data() {
    return {
      loading: false,
      search: '',
      rowsPerPageItems: [20],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
      },
      currentIndex: 1,
      serverData: null,
      headers: [
        { text: 'AGS', sortable: false, value: 'ags', align: 'left' },
        {
          text: 'Kassenzeichen',
          sortable: false,
          value: 'referenceNumber',
          align: 'left',
        },
        {
          text: 'Gemeindename',
          sortable: false,
          value: 'municipalityName',
          align: 'left',
        },
        {
          text: 'Ortsteil von',
          sortable: false,
          value: 'parent.municipality',
          align: 'left',
        },
        {
          text: 'Verw. Gemeinde',
          sortable: false,
          value: 'addressAdministrativeMunicipality.city',
          align: 'left',
        },
        {
          text: 'Kreditor',
          sortable: false,
          value: 'creditor',
          align: 'left',
        },
        {
          text: 'Einh. Hebesatz (Jahr)',
          sortable: false,
          value: 'uniformMunicipalRateSince',
          align: 'left',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['currentCompany']),
    validSearchTerm() {
      if (this.search.length >= 3) {
        return this.search
      }
      return ''
    },
    items() {
      return this.serverData.data
    },
    isEditor() {
      return this.$can(this.$permissions.addPaymentData)
    },
  },
  watch: {
    'pagination.page': {
      handler: 'loadPage',
      immediate: true,
    },
    'currentCompany.id'() {
      // when company switches keep searchterm and reset fetched data
      this.loadPage(1)
    },
    validSearchTerm() {
      // when the valid searchterm changes, reset fetching data
      this.loadPage(1)
    },
  },
  methods: {
    debounceSearch: debounce(function (value) {
      this.search = value
    }, 200),
    async loadPage(page) {
      if (!this.loading) {
        try {
          this.loading = true
          this.serverData = await masterdataApi.searchEntries(
            this.currentCompany.id,
            this.validSearchTerm,
            page
          )
          this.pagination.totalItems = this.serverData.total
          this.pagination.page = this.serverData.current_page
          this.pagination.rowsPerPage = this.serverData.per_page
        } catch (error) {
          Sentry.captureException(error)
        } finally {
          this.loading = false
        }
      }
    },
  },
  mounted() {
    this.$refs.search?.focus()
  },
}
</script>

<style scoped></style>
